<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }} <span>Share {{ title }}</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleShareLocation" v-slot="{ errors }" ref="share-location-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Location</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.location }">
                                    <Field autocomplete="off" name="location" v-model="selectedLocations" rules="required">
                                        <Multiselect
                                            v-model="selectedLocations"
                                            label="company_name"
                                            value-prop="id"
                                            mode="tags"
                                            :options="organizationLocationsOptions"
                                            :searchable="true"
                                            placeholder="Select Location..">
                                        </Multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="location" class="text-danger" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleShareLocation"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Sharing' : 'Share' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Share Location Asset',

        data () {
            return {
                form: {
                    asset_id: '',
                    asset_type: '',
                    locations: []
                },
                selectedLocations: [],
                organizationLocationsOptions: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedAsset: Object,
            title: String,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            organizationLocations (organizationLocations) {
                const vm = this;

                if (organizationLocations.length) {
                    vm.organizationLocationsOptions = organizationLocations.filter(location => location.is_disable == 0 && location.user_id != vm.user.id);
                }

                if (vm.companyLocations && vm.companyLocations.company) {
                    const company = JSON.parse(JSON.stringify(vm.companyLocations.company));
                    company.company_name = company.full_name;
                    company.user = company;

                    vm.organizationLocationsOptions.unshift(company);
                }
            },
        },

        components: {
            Multiselect,
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState ({
            user: state => state.authModule.user,
            loader: state => state.commonModule.loader,
            organizationLocations: state => state.authModule.organizationLocations,
            companyLocations: state => state.authModule.companyLocations,
        }),

        mounted () {
            const vm = this;

            if (vm.organizationLocationsOptions.length == 0) {
                vm.getOrganizationLocations();
            }
        },

        methods: {
            ...mapActions({
                shareToLocation: 'commonModule/shareToLocation',
                getOrganizationLocations: 'authModule/getOrganizationLocations',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form.asset_id     = vm.selectedAsset.id;
                vm.form.asset_type   = vm.title.toLowerCase();
                vm.form.locations    = [];
                vm.selectedLocations = [];
            },

            handleShareLocation () {
                const vm = this;

                const shareLocationForm = vm.$refs['share-location-form'];

                shareLocationForm.validate().then((result) => {
                    if (result.valid) {

                        vm.selectedLocations.forEach((index) => {
                            let result = vm.organizationLocationsOptions.find( ({ id }) => id == index );

                            if (result) {
                                vm.form.locations.push({ 'location_id': index, 'user_id': result.user.id });
                            }
                        });

                        vm.form.setFieldError = shareLocationForm.setFieldError;
                        vm.form.closeModal    = vm.closeModal;

                        vm.shareToLocation(vm.form);
                    }
                });
            }
        }
    }
</script>
